<template>
    <main>
        <Navbar />
        <div class="main">
            <div class="container">
                <div class="btn-group w-100" role="group">
                   <button type="button" class="btn btn-secondary" @click="cat = 1" :class="{'active': cat == 1}" :disabled="cat == 1">
                        <i class="fas fa-plus-circle mr-2" v-show="platform == 'desktop'"></i>
                        <span>Novo</span>
                   </button>
                   <button type="button" class="btn btn-secondary" @click="cat = 2" :class="{'active': cat == 2}" :disabled="cat == 2">
                        <i class="fas fa-gifts mr-2" v-show="platform == 'desktop'"></i>
                        <span>Sorteios</span>
                        <span class="badge badge-warning float-right" :class="{'w-100': platform == 'mobile'}" v-show="cat == 2 && total_sweepstakes > 0">
                            {{ total_sweepstakes }}
                        </span>
                   </button>
               </div><!-- /btn-group -->
               <div v-show="loading == true" class="alert alert-warning mt-3" role="alert">
                    <div class="alert-heading text-center mb-0">
                        <div class="spinner-border mr-2 align-middle" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                        <span>Carregando, aguarde...</span>
                    </div>
                </div><!-- /alert-warning -->
                <div v-show="error" class="alert alert-danger mt-3" role="alert">
                    <h4 class="alert-heading">Ops, ocorreu um problema!</h4>
                    <hr />
                    <p class="mb-0">{{error}}</p>
                    <hr />
                    <div class="row ml-0 mt-3 mr-0">
                        <div class="col pl-0" :class="{'d-contents': platform == 'mobile'}">
                            <select class="form-control" :class="{'mb-3': platform == 'mobile'}" v-model="status">
                                <option value="1">Disponível</option>
                                <option value="2" selected>Finalizado</option>
                            </select>
                        </div>
                        <div class="col pl-0 pr-0" :class="{'d-contents': platform == 'mobile'}">
                            <button class="btn btn-warning btn-block" @click="listSweepstakes"><i class="fas fa-search mr-2"></i>Buscar</button>
                        </div><!-- /col -->
                    </div><!-- /row -->
                </div><!-- /alert-danger -->
                <div class="mt-3 mb-3">
                    <template v-if="cat == 1">
                        <form @submit.prevent="newSweepstakes">
                            <div class="form-group">
                                <label for="name" class="text-light">Nome</label>
                                <input type="text" class="form-control" v-model="sweepstakes.name" v-text-only id="name" placeholder="Informe o nome do sorteio" autocomplete="off" spellcheck="false" required>
                            </div>
                            <div class="form-group">
                                <label for="date_in" class="text-light">Horário de início</label>
                                <input type="datetime-local" class="form-control" v-model="sweepstakes.date_in" id="date_in" placeholder="Informe o nome do produto" autocomplete="off" spellcheck="false" required>
                            </div>
                            <div class="form-group">
                                <label for="date_fn" class="text-light">Horário de encerramento</label>
                                <input type="datetime-local" class="form-control" v-model="sweepstakes.date_fn" id="date_fn" placeholder="Informe o nome do produto" autocomplete="off" spellcheck="false" required>
                            </div>
                            <div class="form-group">
                                <label for="price" class="text-light">Valor mínimo da aposta para participar</label>
                                <input type="text" class="form-control" v-model="sweepstakes.price" v-number-only id="price" placeholder="Informe o valor mínimo da aposta para participar" autocomplete="off" spellcheck="false" required>
                            </div>
                            <div class="form-group">
                                <button type="submit" class="btn btn-primary mb-3" :class="{'btn-block': platform == 'mobile', 'float-right': platform == 'desktop'}" :disabled="loading == true">
                                    <i class="fas fa-check-circle mr-2"></i>
                                    <span>Criar sorteio</span>
                                </button>
                            </div>
                        </form>
                    </template>
                    <template v-else-if="cat == 2 && total_sweepstakes">
                        <div class="row ml-0 mt-3 mr-0" :class="{'mb-3': platform == 'desktop'}">
                            <div class="col pl-0" :class="{'d-contents': platform == 'mobile'}">
                                <select class="form-control" :class="{'mb-3': platform == 'mobile'}" v-model="status">
                                    <option value="1">Disponível</option>
                                <option value="2" selected>Finalizado</option>
                                </select>
                            </div>
                            <div class="col pl-0 pr-0" :class="{'d-contents': platform == 'mobile'}">
                                <button class="btn btn-warning btn-block" @click="listSweepstakes">
                                    <i class="fas fa-search mr-2"></i>Buscar
                                </button>
                            </div><!-- /col -->
                        </div><!-- /row -->
                        <div class="card mb-3" v-for="(item, index) in sweepstakesItems" :key="index">
                            <div class="card-header">
                                <div class="row align-items-center">
                                    <div class="col text-truncate">{{ item.nome }}</div>
                                    <div class="col-auto">
                                        <button class="btn btn-primary btn-sm btn-block" @click="editSweepstakes(item, index)">
                                            <i class="fas fa-edit"></i>
                                        </button>
                                    </div>
                                    <div class="col-auto">
                                        <button class="btn btn-danger btn-sm btn-block" @click="deleteSweepstakes(item, index)">
                                            <i class="fas fa-trash"></i>
                                        </button>
                                    </div>
                                </div>
                            </div><!-- /card-header -->
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-sm-4">Status</div>
                                    <div class="col-sm-8" :class="item.status == 1 ? 'text-success' : 'text-danger'">
                                        {{ item.status == 1 ? 'Disponível' : 'Finalizado' }}
                                    </div>
                                </div>
                                <hr />
                                <template v-if="item.status == 2">
                                    <div class="row">
                                        <div class="col-sm-4">Ganhador</div>
                                        <div class="col-sm-8 text-success text-uppercase">{{ item.ganhador_nome }}</div>
                                    </div>
                                    <hr />
                                    <div class="row">
                                        <div class="col-sm-4">Código da sorte</div>
                                        <div class="col-sm-8 text-success text-uppercase">{{ item.ganhador_codigo }}</div>
                                    </div>
                                    <hr />
                                    <div class="row">
                                        <div class="col-sm-4">Vendedor da aposta</div>
                                        <div class="col-sm-8 text-success text-uppercase">{{ item.ganhador_vendedor }}</div>
                                    </div>
                                    <hr />
                                    <template v-if="item['ganhador_vendedor_endereco'] != undefined">
                                        <div class="row">
                                            <div class="col-sm-4">Vendedor endereço</div>
                                            <div class="col-sm-8 text-success text-uppercase">{{ item.ganhador_vendedor_endereco }}</div>
                                        </div>
                                        <hr />
                                    </template>
                                    <div class="row">
                                        <div class="col-sm-4">Qtd. Apostas participantes</div>
                                        <div class="col-sm-8 text-danger text-uppercase">{{ format_value(item.total_apostas) }}</div>
                                    </div>
                                    <hr />
                                </template>
                                <div class="row">
                                    <div class="col-sm-4">Horário de início</div>
                                    <div class="col-sm-8 text-muted">{{ item.date_in | formatDate }}</div>
                                </div>
                                <hr />
                                <div class="row">
                                    <div class="col-sm-4">Horário de encerramento</div>
                                    <div class="col-sm-8 text-muted">{{ item.date_fn | formatDate }}</div>
                                </div>
                                <hr />
                                <div class="row">
                                    <div class="col-sm-4">Valor mínimo da aposta para participar</div>
                                    <div class="col-sm-8 text-danger">R$ {{ format_coin(item.preco) }}</div>
                                </div>
                                <div class="row mt-3" v-if="item.status == 1">
                                    <div class="col-12">
                                        <button class="btn btn-block btn-success" @click="startDraw(item)">
                                            <i class="fas fa-random mr-2"></i>Sortear
                                        </button>
                                    </div>
                                </div>
                            </div><!-- /card-body -->
                        </div><!-- /card -->
                    </template>
                </div><!-- /mt-3 mb-3 -->
            </div>
        </div>
      <loading :active.sync="isLoading" :can-cancel="false" :is-full-page="true"></loading>
    </main>
</template>

<script>
import {api} from '../../api'
import Navbar from '../Navbar'
import Swal from 'sweetalert2'
import moment from 'moment-timezone'
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'

export default {
    data() {
        return {
            cat: 1,
            status: 1,
            error: '',
            loading: false,
            isLoading: false,
            sweepstakes: {
                name: '',
                price: '',
                date_in: '',
                date_fn: ''
            },
            sweepstakesItems: [],
            total_sweepstakes: 0,
            platform: /iPhone|iPad|iPod|Android/i.test(navigator.userAgent) ? 'mobile' : 'desktop'
        }
    },
    components: {
        Navbar,
        Loading
    },
    methods: {
        newSweepstakes() {

            const self = this;

            if (self.sweepstakes.name.toString().trim() == '') {
                Swal.fire(
                    'Atenção!',
                    'Informe o nome do sorteio!',
                    'warning'
                );
            } else if (self.sweepstakes.date_in.toString().trim() == '') {
                Swal.fire(
                    'Atenção!',
                    'Informe o horário de início!',
                    'warning'
                );
            } else if (self.sweepstakes.date_fn.toString().trim() == '') {
                Swal.fire(
                    'Atenção!',
                    'Informe o horário de encerramento!',
                    'warning'
                );
            } else if (self.sweepstakes.price.toString().trim() == '') {
                Swal.fire(
                    'Atenção!',
                    'Informe o valor mínimo da aposta para participar!',
                    'warning'
                );
            } else {
                
                Swal.fire({
                    title: 'Confirmação',
                    html: `Você deseja mesmo criar esse sorteio?`,
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    cancelButtonText: 'Cancelar',
                    confirmButtonText: 'Sim'
                }).then((result) => {

                    if (result.value) {

                        self.isLoading = true;

                        api.post('dashboard/sweepstakes', {
                            action: 'create',
                            sweepstakes: self.sweepstakes
                        }).then((response) => {
                            switch(response.data.result) {
                                case 'success':
                                    self.clearProduct();
                                    Swal.fire(
                                        'Parabéns!',
                                        'Seu foi cadastrado com sucesso!',
                                        'success'
                                    );
                                break;
                                default:
                                    Swal.fire(
                                        'Falha!',
                                        response.data.message,
                                        'warning'
                                    );
                                break;
                            }
                        }).catch((error) => {
                            try {
                                Swal.fire(
                                    'Falha',
                                    self.network_erros[error.status]['message'],
                                    'error'
                                );
                            } catch(e) {
                                Swal.fire(
                                    'Falha',
                                    self.network_erros[408]['message'],
                                    'error'
                                );
                            }
                        }).finally(() => {
                            self.isLoading = false;
                        });
                    }
                });
            }
        },
        listSweepstakes() {

            const self = this;

            self.error = '';
            self.loading = true;
            self.sweepstakesItems = [];

            api.get(`dashboard/sweepstakes?status=${self.status}`).then((response) => {
                switch(response.data.result) {
                    case 'success':
                        self.sweepstakesItems = response.data.items;
                    break;
                    case 'nothing_found':
                        self.error = 'Não encontramos nenhum sorteio!';
                    break;
                    default:
                        self.error = response.data.message;
                    break;
                }
            }).catch((error) => {
                try {
                    self.error = self.network_erros[error.status]['message'];
                } catch(e) {
                    self.error = self.network_erros[408]['message'];
                }
            }).finally(() => {
                self.loading = false;
            });
        },
        deleteSweepstakes(item, index) {

            const self = this;

            Swal.fire({
                title: 'Excluir sorteio',
                html: `Você deseja mesmo excluir o sorteio "<b>${item.nome}</b>" ?`,
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                cancelButtonText: 'Cancelar',
                confirmButtonText: 'Sim'
            }).then(function(result) {

                if (result.value) {

                    self.isLoading = true;

                    api.delete(`dashboard/sweepstakes?id=${item._id}`).then((response) => {
                        switch(response.data.result) {
                            case 'success':
                                self.removeSweepstakesList(index);
                                Swal.fire(
                                    'Parabéns!',
                                    'Sorteio excluído com sucesso!',
                                    'success'
                                );
                            break;
                            default:
                                Swal.fire(
                                    'Falha!',
                                    response.data.message,
                                    'warning'
                                );
                            break;
                        }
                    }).catch((error) => {
                        try {
                            Swal.fire(
                                'Falha',
                                self.network_erros[error.status]['message'],
                                'error'
                            );
                        } catch(e) {
                            Swal.fire(
                                'Falha',
                                self.network_erros[408]['message'],
                                'error'
                            );
                        }
                    }).finally(() => {
                        self.isLoading = false;
                    });
                }
            });
        },
        async editSweepstakes(item, index) {
            try {
                const self = this;

                const { value: formValues } = await Swal.fire({
                    title: 'Editar sorteio',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    cancelButtonText: 'Cancelar',
                    confirmButtonText: 'Salvar',
                    html: `
                        <label class="d-block text-left mb-0">Nome</label>
                        <input id="swal-input1" class="swal2-input" value="${item.nome}" autocomplete="off" spellcheck="false">

                        <label class="d-block text-left mb-0">Horário de início</label>
                        <input id="swal-input3" type="datetime-local" class="swal2-input" value="${self.formatDateTimeLocal(item.date_in)}" autocomplete="off" spellcheck="false">

                        <label class="d-block text-left mb-0"Horário de encerramento</label>
                        <input id="swal-input4" type="datetime-local" class="swal2-input" value="${self.formatDateTimeLocal(item.date_fn)}" autocomplete="off" spellcheck="false">

                        <label class="d-block text-left mb-0">Valor mínimo da aposta para participar</label>
                        <input id="swal-input2" class="swal2-input" value="${item.preco}" autocomplete="off" spellcheck="false">
                    `,
                    focusConfirm: false,
                    preConfirm: () => {
                        return {
                            id: item._id,
                            name: document.getElementById('swal-input1').value,
                            price: document.getElementById('swal-input2').value,
                            date_in: document.getElementById('swal-input3').value,
                            date_fn: document.getElementById('swal-input4').value
                        }
                    },
                    onOpen: () => {
                        document.getElementById("swal-input1").addEventListener("input", (e) => {
                           return e.target.value = e.target.value.replace(/[^a-zà-ú0-9\\!?.,-\s]+/gi,'');
                        });

                        document.getElementById("swal-input2").addEventListener("input", (e) => {
                           return e.target.value = e.target.value.replace(/[^0-9]/g, '');
                        });
                    }
                });

                if (formValues instanceof Object) {

                    if (formValues.name.toString().trim() == '') {
                        Swal.fire(
                            'Atenção!',
                            'Informe o nome do sorteio!',
                            'warning'
                        );
                    } else if (formValues.date_in.toString().trim() == '') {
                        Swal.fire(
                            'Atenção!',
                            'Informe o horário de início!',
                            'warning'
                        );
                    } else if (formValues.date_fn.toString().trim() == '') {
                        Swal.fire(
                            'Atenção!',
                            'Informe o horário de encerramento!',
                            'warning'
                        );
                    } else if (formValues.price.toString().trim() == '') {
                        Swal.fire(
                            'Atenção!',
                            'Informe o valor mínimo da aposta para participar!',
                            'warning'
                        );
                    } else {
                        
                        self.isLoading = true;

                        api.put('dashboard/sweepstakes', {
                            sweepstakes: formValues
                        }).then((response) => {
                            switch(response.data.result) {
                                case 'success':
                                    self.updateSweepstakesList(index, formValues);
                                    Swal.fire(
                                        'Parabéns!',
                                        'As alterações foram salvas com sucesso!',
                                        'success'
                                    );
                                break;
                                default:
                                    Swal.fire(
                                        'Falha!',
                                        response.data.message,
                                        'warning'
                                    );
                                break;
                            }
                        }).catch((error) => {
                            try {
                                Swal.fire(
                                    'Falha',
                                    self.network_erros[error.status]['message'],
                                    'error'
                                );
                            } catch(e) {
                                Swal.fire(
                                    'Falha',
                                    self.network_erros[408]['message'],
                                    'error'
                                );
                            }
                        }).finally(() => {
                            self.isLoading = false;
                        });
                    }
                }
            } 
            catch(e) 
            {
                // window.console.log(e.message)
            }
        },
        startDraw(item) {

            const self = this;
            let timerInterval;

            Swal.fire({
                title: 'Iniciar sorteio',
                html: `Você deseja mesmo iniciar o sorteio "<b>${item.nome}</b>" ?`,
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                cancelButtonText: 'Cancelar',
                confirmButtonText: 'Sim'
            }).then(function(result) {

                if (result.value) {

                    Swal.fire({
                        title: `Aguarde...`,
                        html: `
                            <p class="m-0">Iniciando o sorteio!</p>
                            <p>Aguarde <b></b> o sorteio vai iniciar.</p>
                        `,
                        timer: 10000,
                        timerProgressBar: true,
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        allowEnterKey: false,
                        showConfirmButton: false,
                        showCancelButton: false,
                        onOpen: () => {
                            Swal.showLoading()
                            const b = Swal.getHtmlContainer().querySelector('b')
                            timerInterval = setInterval(() => {
                                b.textContent = self.toSecond(Swal.getTimerLeft())
                            }, 100)
                        },
                        onClose: () => {
                            clearInterval(timerInterval)
                        }
                    }).then((result) => {

                        if (result.dismiss === Swal.DismissReason.timer) {

                            self.isLoading = true;
                            
                            api.post('dashboard/sweepstakes', {
                                id: item._id,
                                action: 'start_draw'
                            }).then((response) => {
                                switch(response.data.result) {
                                    case 'success':
                                        Swal.fire({
                                            icon: "success",
                                            title: "Código da sorte",
                                            html: `<div class="d-flex flex-column align-items-center justify-content-center">
                                                <div>
                                                    <h3 class="text-success text-uppercase">${response.data.ganhador_codigo}</h3>
                                                </div> 
                                                <div class="border-top mt-3 pt-3 w-100">
                                                    <h3>Sorteio</h3>
                                                </div> 
                                                <div>
                                                    <h3 class="text-danger text-uppercase">${response.data.sorteio}</h3>
                                                </div> 
                                                <div class="border-top mt-3 pt-3 w-100">
                                                    <h3>Qtd. Apostas</h3>
                                                </div> 
                                                <div>
                                                    <h3 class="text-danger text-uppercase">${self.format_value(response.data.total_apostas)}</h3>
                                                </div>
                                                <div class="border-top mt-3 pt-3 w-100">
                                                    <h3>Valor da aposta</h3>
                                                </div> 
                                                <div>
                                                    <h3 class="text-danger text-uppercase">R$ ${self.format_coin(response.data.ganhador_valor)}</h3>
                                                </div>
                                                <div class="border-top mt-3 pt-3 w-100">
                                                    <h3>Ganhador</h3>
                                                </div> 
                                                <div>
                                                    <h3 class="text-success text-uppercase">${response.data.ganhador_nome}</h3>
                                                </div>
                                                <div class="border-top mt-3 pt-3 w-100">
                                                    <h3>Vendedor</h3>
                                                </div> 
                                                <div>
                                                    <h3 class="text-danger text-uppercase">${response.data.ganhador_vendedor}</h3>
                                                </div>
                                                <div class="border-top mt-3 pt-3 w-100">
                                                    <h3>Endereço</h3>
                                                </div> 
                                                <div>
                                                    <h3 class="text-danger text-uppercase">${response.data.ganhador_vendedor_endereco}</h3>
                                                </div>
                                            </div>`,
                                            footer: `<a class="text-uppercase" href="/ticket/${response.data.ganhador_codigo}" target="_blank">Ver bilhete</a>`
                                        });
                                    break;
                                    default:
                                        Swal.fire(
                                            'Atenção!',
                                            response.data.message,
                                            'warning'
                                        );
                                    break;
                                }
                            }).catch((error) => {
                                try {
                                    Swal.fire(
                                        'Falha',
                                        self.network_erros[error.status]['message'],
                                        'error'
                                    );
                                } catch(e) {
                                    Swal.fire(
                                        'Falha',
                                        self.network_erros[408]['message'],
                                        'error'
                                    );
                                }
                            }).finally(() => {
                                self.isLoading = false;
                            });
                        }
                    });
                }
            });
        },
        updateSweepstakesList(index, data) {
            if (this.sweepstakesItems[index] != undefined) {
                this.sweepstakesItems[index].nome = data.name;
                this.sweepstakesItems[index].preco = data.price;
                this.sweepstakesItems[index].date_in = data.date_in;
                this.sweepstakesItems[index].date_fn = data.date_fn;
            }
        },
        removeSweepstakesList(index) {
            if (this.sweepstakesItems[index] != undefined) {
                this.sweepstakesItems.splice(index, 1);
            }
        },
        clearProduct() {
            this.sweepstakes.name = '';
            this.sweepstakes.price = '';
            this.sweepstakes.date_in = '';
            this.sweepstakes.date_fn = '';
        },
        toSecond(ms) {
            return Math.floor((ms/1000) % 60);
        },
        formatDateTimeLocal(date) {
            return moment(date).tz('America/Recife').format('YYYY-MM-DD[T]HH:mm');
        }
    },
    watch: {
        cat(value) {
            this.error = '';

            if (value == 2) {
                this.listSweepstakes();
            }
        },
        sweepstakesItems(values) {
            this.total_sweepstakes = values.length;
        },
    },
    directives: {
        textOnly: {
            bind: (el) => {
                el.handler = () => {
                    el.value = el.value.replace(/[^a-zà-ú0-9\\!?.,-\s]+/gi,'');
                }
                el.addEventListener('input', el.handler)
            },
            unbind: (el) => {
                el.removeEventListener('input', el.handler)
            }
        },
        numberOnly: {
            bind: (el) => {
                el.handler = () => {
                    el.value = el.value.replace(/[^0-9]/g,"");
                }
                el.addEventListener('input', el.handler)
            },
            unbind: (el) => {
                el.removeEventListener('input', el.handler)
            }
        }
    },
    filters: {
        formatDate(date) {
            return moment(date).tz('America/Recife').format('DD/MM/YYYY [às] HH:mm');
        }
    }
}
</script>

<style scoped>
.main {
    margin-top: 80px;
}
.btn .badge {
    top: 3px!important;
}
</style>